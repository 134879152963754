const photographicImage = {
    altText: 'Alt text',
    localFile: {
        childImageSharp: {
            gatsbyImageData: {
                layout: 'constrained',
                placeholder: {
                    fallback:
                        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAABC0lEQVQ4y6WTyQ6DMAxE+///xg4HEDsSCBA7Z1xNCG1EQtWWw0iGxC8zDjxM06S7Mgxj43X/4C+YTpv+A4owXddJ07TX87dQyeHRHAQBJUlClmX9BFVGdl2XxnGkdV0ZFE5vzdC2barrmoZhIN/3WXzVPFWOpRmKUDhVAcRZKy5QdqhqEmvMFQcezk+Hqi/lqoaqqqK2ba+gnx2iSXRYliVN08TUNI0KKs8Qws3GcUxd15HneWwzYPM8s8uCLqByZMCiKGKfDpoQDzFRHzCsXUA3bmx3CFgYhq+Gvu9ZfTyfhfUTdONOd2CaprQsC4v2rQDET8DH83boOA7leU5Zlv2loiiQTo58R2LkJ9F1YZ7jVWzUAAAAAElFTkSuQmCC'
                },
                backgroundColor: 'transparent',
                images: {
                    fallback: {
                        src: '/static/bade74c991011096287010458f7b77d2/efa6e/storybook-image-placeholder.png',
                        srcSet:
                            '/static/bade74c991011096287010458f7b77d2/74496/storybook-image-placeholder.png 576w,\n/static/bade74c991011096287010458f7b77d2/efa6e/storybook-image-placeholder.png 681w',
                        sizes: '(min-width: 681px) 681px, 100vw'
                    },
                    sources: [
                        {
                            srcSet:
                                '/static/bade74c991011096287010458f7b77d2/3382f/storybook-image-placeholder.avif 576w,\n/static/bade74c991011096287010458f7b77d2/0bfc4/storybook-image-placeholder.avif 681w',
                            type: 'image/avif',
                            sizes: '(min-width: 681px) 681px, 100vw'
                        },
                        {
                            srcSet:
                                '/static/bade74c991011096287010458f7b77d2/e9101/storybook-image-placeholder.webp 576w,\n/static/bade74c991011096287010458f7b77d2/dbaf6/storybook-image-placeholder.webp 681w',
                            type: 'image/webp',
                            sizes: '(min-width: 681px) 681px, 100vw'
                        }
                    ]
                },
                width: 681,
                height: 528
            }
        }
    }
}

export default photographicImage
