import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import photographicImage from '/src/dummy-data/images/photographicImage'

const Image = ({ data, fit, className }) => {
    const image = data.localFile?.childImageSharp?.gatsbyImageData

    return <GatsbyImage image={image} alt={data.altText} objectFit={fit} className={className} />
}

Image.propTypes = {
    /**
     * Data
     */
    data: PropTypes.object.isRequired,
    /**
     * Optional image fit
     */
    fit: PropTypes.oneOf(['cover', 'contain']),
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

Image.defaultProps = {
    data: photographicImage,
    fit: 'contain'
}

export default Image
